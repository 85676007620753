
// Font for Heading
@font-face {
    font-family: 'Univers Next For Morn';
    src: url('../../assets/fonts/UniversNextforMORNPC-CnLt_0.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Univers Next For Morn';
    src: url('../../assets/fonts/UniversNextforMORNPC-CnLtIt_0.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Univers Next For Morn';
    src: url('../../assets/fonts/UniversNextforMORNPC-CnBd_1.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Univers Next For Morn';
    src: url('../../assets/fonts/UniversNextforMORNPC-CnBdIt_0.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}
