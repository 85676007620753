@import '../../styles/shared';

// Colors
$checkmark--defaultColor: $lightGrey;
$checkmark--checkedColor: $color-primary;
$checkmark--checkedColorContainer: $lightestBlue;

// Size
$checkmark--size: 22px;
$checkmark--label-spacing: 6px;
$checkmark--item-spacing: 8px;
$checkmark--inner-padding: 5px;
$checkmark--font-size: 14px;

// Transition duration
$checkmark--transition-duration: 250ms;

.nw-checkmark {
    max-width: 100%;

    & + .nw-checkmark {
        margin-top: $checkmark--item-spacing;
    }

    &__container {
        display: inline-block;
        @include user-select;
        cursor: pointer;
    }

    &__switcher {
        display: inline-block;
        vertical-align: middle;
        width: $checkmark--size;
        height: $checkmark--size;
        border-radius: 50%;
        overflow: hidden;
        background-color: $checkmark--defaultColor;
        position: relative;
        transition: all $checkmark--transition-duration ease-out;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 63.63636364% auto;
        }
    }

    &__label {
        margin-left: $checkmark--label-spacing;
        vertical-align: middle;
        font-size: $checkmark--font-size;
    }

    &__input {
        display: none;
    
        &:checked {
            & + .nw-checkmark__container {
                .nw-checkmark__switcher {
                    background-color: $checkmark--checkedColor;
    
                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8.88' viewBox='0 0 12 8.88'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23fff;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M37.648,32l-6.734,6.3-2.581-2.371L27,37.239l3.874,3.641h.017L39,33.279Z' transform='translate(-27 -32)'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }

    &--disabled {
        .nw-checkmark__container {
            opacity: .5;
            cursor: no-drop;
        }
    }

    &--has-label {

        .nw-checkmark__container {
            padding-left: $checkmark--size + $checkmark--label-spacing;
        }

        .nw-checkmark__switcher {
            margin-left: -($checkmark--size + $checkmark--label-spacing);
        }
    }

    &--has-container {
        display: inline-block;
        margin-right: $checkmark--item-spacing;
        margin-top: 0 !important;
        margin-bottom: $checkmark--item-spacing;
        color: $black;

        .nw-checkmark {
            &__container {
                @include displayFlex;
                align-items: center;
                padding: $checkmark--inner-padding;
                background-color: $checkmark--defaultColor;
                border-radius: ($checkmark--size + $checkmark--inner-padding)*3;
            }

            &__switcher {
                background-color: white;
                margin-left: 0 !important;
            }

            &__label {
                padding-right: $checkmark--inner-padding;
                // flex: 1;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
            }

            &__input {
                &:checked {
                    & + .nw-checkmark__container {
                        background-color: $checkmark--checkedColorContainer;
                    }
                }
            }
        }
    }
}

