@import '../../styles/shared';

$page--width: 100vw;
$page--height: 100vh;
$page--bgColor: $lightestBlue;

$container--desktopWidth: 950px;
$container--sideHeaderBg: $white;
$container--sideHeaderWidth: 40%;
$container--sideHeaderLogoWidth: 160px;
$container--sideHeaderBorderColor: $color-base-border;
$container--contentWidth: 60%;
$container--contentBg: $color-secondary;
$container--padding: $spacing--basePadding;
$container--contentPadding: $spacing--bigPadding;
$container--paddingMobile: $container--padding*2 $container--padding/2 $container--padding $container--padding/2;
$container--buttonSpaceTop: $spacing--baseMargin;
$container--titleWeight: $font-weight--light;
$container--titleColor: $white;
$container--titleMargin: 0 0 $spacing--baseMargin+10 0;
$container--boxShadow: 0 5px 14px rgba(35,35,35,0.125);

.page-auth {
    background: $page--bgColor;
    .App {
        width: $page--width;
        height: auto;
        @include displayFlex;
        align-items: center;
        justify-content: center;
        @include desktop {
            height: $page--height;
        }
    }
}

.db-auth-page {
    // width: $page--width;
    // height: $page--height;
    @include displayFlex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 100%;

    & > section {
        min-width: 100%;
        position: relative;
        @include displayFlex;
        flex-direction: column;
    }

    & > img {
        margin-top: 20px;
        max-width: 110px;
        position: absolute;
        bottom: 10px;
        right: 10px;

        @include desktop {
            position: relative;
            right: 0;
            bottom: 0;
        }
    }

    &__sideHeader {
        width: 100%;
        min-height: auto;
        background: $container--sideHeaderBg;
        padding: $container--paddingMobile;

        @include displayFlex;
        align-items: center;
        justify-content: center;
        text-align: center;

        img {
            max-width: $container--sideHeaderLogoWidth;
            height: auto;
        }

        .tool-title {
            text-transform: uppercase;
            border-top: 1px solid $container--sideHeaderBorderColor;
            padding-top: $spacing--baseMargin;
            text-align: center;
        }
    }

    &__content {
        width: 100%;
        height: auto;
        background: $container--contentBg;
        padding: 0 $spacing--bigPadding/3 $spacing--bigPadding*1.5 $spacing--bigPadding/3;

        .title {
            font-weight: $container--titleWeight;
            font-size: 150%;
            color: $container--titleColor;
            margin: $spacing--baseMargin;
            text-align: center;
        }

        &__action {
            & > div {
                @include displayFlex;
                justify-content: space-between;
                margin-top: $container--buttonSpaceTop;
            }
            label {
                display: block;
                margin-top: 10px;

                span {
                    color: $white;
                    margin-left: 3px;
                }
            }
        }
    }

    @include desktop {
        min-width: $container--desktopWidth;
        & > section {
            flex-direction: row;
            justify-content: stretch;
            width: $container--desktopWidth;
            @include box-shadow($container--boxShadow);
        }

        &__sideHeader {
            padding: $container--padding;
            width: $container--sideHeaderWidth;
            min-height: 40vh;
        }
        &__content {
            padding: $container--contentPadding;
            width: $container--contentWidth;
            height: auto;

            .title {
                font-size: $font-size--header;
                margin: $container--titleMargin;
            }
        }
    }
}