@import '../../styles/shared';

// Colors
$button--defaultColor: $color-tertiary;
$button--textColor: $color-text;
$button--textInvertedColor: $color-text-inverted;
$button--defaultBorderColor: $color-base-border;
$button--primaryColor: $color-primary;
$button--secondaryColor: $color-secondary;
$button--tertiaryColor: $color-tertiary;
$button--linkColor: $color-primary;
$button--disabledColor: $lightGrey100;

// Size, alignment, typography, etc...
$button--radius: $radius--smaller;
$button--radiusFull: $radius--full;
$button--smallMargin: $spacing--smallMargin;
$button--baseMargin: $spacing--baseMargin;
$button--bigMargin: $spacing--bigMargin;
$button--smallPadding: $spacing--smallPadding;
$button--basePadding: $spacing--basePadding;

$button--smallFont: $font-size--small;
$button--baseFont: $font-size--base;
$button--bigFont: $font-size--big;

$button--minWidth: 84px;

.navbar {
    .nw-button {
        padding-right: unset;
        padding-left: unset;
    }
}
.nw-button {
    min-width: $button--minWidth;
    min-height: $button--baseSize;
    text-align: center;
    line-height: auto;
    font-size: $font-size--base;
    font-weight: $font-weight--medium;
    background: inherit;
    border: 1px solid $button--defaultBorderColor;
    color: $button--textColor;
    @include displayFlex;
    align-items: center;
    justify-content: center;
    @include border-radius($button--radius);
    padding-right: $button--basePadding;
    padding-left: $button--basePadding;
    cursor: pointer;
    
    &:focus, &:active {
        outline: none;
    }

    &--disabled {
        cursor: not-allowed;
        opacity: .70;
        &:hover {
            background: unset;
        }
    }

    &--big {
        min-height: $button--largeSize;
        font-size: $button--bigFont;
        width: 100%;
        // max-width: $buttonLargeWidth;
    }

    &--small {
        min-height: $button--smallSize;
        font-size: $button--smallFont;
        padding-right: $button--smallPadding;
        padding-left: $button--smallPadding;
    }
    
    &--outline {
        border: 1px solid inherit; 
        background: inherit;
        color: inherit;
    }

    &--link {
        background: transparent;
        height: auto;
        width: auto;
        font-size: $button--baseFont;
        color: $button--linkColor;
        letter-spacing: 0.5px;
        border: none;
        padding-right: unset;
        padding-left: unset;
        cursor: pointer;

        svg {
            margin-left: $button--smallMargin;
        }

        &.nw-button--rtl {
            flex-direction: row-reverse;
            
            .arrow {
                @extend %transform-rtl;
            }
        }
    }

    &:active, &:focus {
        background: $button--secondaryColor;
        border-color: $button--secondaryColor;
        color: $white;
    }
    
    &--primary {
        background: $button--primaryColor;
        border: 1px solid $button--primaryColor;
        color: $button--textInvertedColor;

        &:hover {
            @include lightenBgColor($button--primaryColor);
        }

        &:active, &:focus {
            @include darkenBgColor($button--primaryColor);
        }
        
        &.nw-button--outline {
            border: 1px solid $button--primaryColor;
            background: inherit;
            color: $button--primaryColor;
        }

        &.nw-button--disabled {
            background: $button--disabledColor;
            border-color: $button--disabledColor;
            color: $color-subText;
        }
    }

    &--secondary {
        background: $button--secondaryColor;
        border: 1px solid $button--secondaryColor;
        color: $button--textInvertedColor;

        &:hover {
            @include lightenBgColor($button--secondaryColor);
        }

        &:active, &:focus {
            @include darkenBgColor($button--secondaryColor);
        }
        
        &.nw-button--outline {
            border: 1px solid $button--secondaryColor;
            background: inherit;
            color: $button--secondaryColor;
        }
    }

    &--tertiary {
        background: $button--tertiaryColor;
        border: 1px solid $button--tertiaryColor;
        color: $color-text-inverted;

        &:hover {
            @include lightenBgColor($button--tertiaryColor);
        }

        &:active, &:focus {
            @include darkenBgColor($button--tertiaryColor);
        }
        
        &.nw-button--outline {
            border: 1px solid $button--tertiaryColor;
            background: inherit;
            color: $button--tertiaryColor;
        }
    }
    
    &--icon {
        background: none;
        padding: 0;
        min-height: auto;
        border: unset;
        min-width: 0;

        &:active, &:focus {
            background: unset;
            color: unset;
            border: unset;
        }

        img {
            width: 100%;
            height: auto;
            display: block;
            margin: 0;
        }
        &:focus {
            outline: none;
        }
    }
    
    &--image {
        border: none;
        background: none;
    }

    &--rounded {
        @include border-radius($button--baseSize);
    }
}

.nw-group-buttons {
    button {
        width: 100%;
        margin-bottom: $button--baseMargin;
        
        &.selected {
            background: $button--secondaryColor;
            color: $button--textInvertedColor;
        }
    }
}