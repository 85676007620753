@import '../../styles/shared';

$alert-setting-spacing: 16px;
$alert-setting-border-color: $lightGrey;
$alert-setting-dark-border-color: rgba($color: $white, $alpha: .3);

.nw-alert-settings {
    &__item {
        &:not(:last-child) {
            padding-bottom: $alert-setting-spacing;
            margin-bottom: $alert-setting-spacing;
            border-bottom: 1px dashed $alert-setting-border-color;
        }
    
        .db-hoc-sidebar & {
            border-bottom-color: $alert-setting-dark-border-color;
        }
    }
}