// Toast custom

$nw-toast-min-height: 56px;
$nw-toast-color--default: $darkGrey;
$nw-toast-color--success: $greenDark;
$nw-toast-color--error: $cherryRed;
$nw-toast-icon-space: 34px;
$nw-toast-icon-size: 14px;
$nw-toast-icon-position: ($nw-toast-icon-space - $nw-toast-icon-size)/2;

.App {
    .Toastify {
        &__toast {
            @include displayFlex;
            position: relative;
            padding-left: $nw-toast-icon-space;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 5px;
            overflow: hidden;
            min-height: $nw-toast-min-height;
            font-family: $fontFamily--regular;
            
            .nw-button {
                color: $nw-toast-color--default;
            }

            &:before {
                content: "";
                width: $nw-toast-icon-space;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: $nw-toast-color--default;
            }

            &:after {
                content: "";
                width: $nw-toast-icon-size;
                height: $nw-toast-icon-size;
                position: absolute;
                top: $nw-toast-icon-position;
                left: $nw-toast-icon-position;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            &-body {
                padding: 0 16px;
                line-height: $lineHeight--base;
                font-size: 14px;

                .nw-sub-header {
                    h1,
                    h2,
                    h3,
                    h4 {
                        font-size: inherit;
                        font-family: $fontFamily--regular;
                        font-weight: normal;
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 0;
                        font-weight: bold;
                    }
                }
            }

            &--success {
                color: $greenDark;
                background: lighten($color: $nw-toast-color--success, $amount: 50);
                
                &::before {
                    background-color: $nw-toast-color--success;
                }

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='m12.422,1.82l-7.856,7.355l-3.011,-2.767l-1.555,1.524l4.52,4.248l0.019,0l9.461,-8.868l-1.578,-1.492z' /%3E%3C/svg%3E");
                }

                .nw-button {
                    color: $nw-toast-color--success;
                }
            }

            &--error {
                color: $nw-toast-color--error;
                background: lighten($color: $nw-toast-color--error, $amount: 37);
                
                &::before {
                    background-color: $nw-toast-color--error;
                }

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' d='m11,0l-4,0l0,12l4,0l0,-12zm-4,16c0,-1.1046 0.89539,-2 2,-2c1.10461,0 2,0.8954 2,2c0,1.1046 -0.89539,2 -2,2c-1.10461,0 -2,-0.8954 -2,-2z' clip-rule='evenodd' fill-rule='evenodd'/%3E%3C/svg%3E");
                }

                .nw-button {
                    color: $nw-toast-color--error;
                }
            }
        }
        &__close-button {
            font-size: 0;

            &::before {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7.999' viewBox='0 0 8 7.999'%3E%3Cpath fill='%23cccccc' d='M14.686,7.494l-3.193,3.194,3.194,3.194-.805.805-3.2-3.194L7.493,14.687l-.806-.805,3.194-3.194L6.687,7.494l.806-.806,3.194,3.194,3.194-3.194Z' transform='translate(-6.687 -6.688)'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            &--success {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7.999' viewBox='0 0 8 7.999'%3E%3Cpath fill='%2330a372' d='M14.686,7.494l-3.193,3.194,3.194,3.194-.805.805-3.2-3.194L7.493,14.687l-.806-.805,3.194-3.194L6.687,7.494l.806-.806,3.194,3.194,3.194-3.194Z' transform='translate(-6.687 -6.688)'/%3E%3C/svg%3E");
                }
            }

            
            &--error {
                &::before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7.999' viewBox='0 0 8 7.999'%3E%3Cpath fill='%23EE4054' d='M14.686,7.494l-3.193,3.194,3.194,3.194-.805.805-3.2-3.194L7.493,14.687l-.806-.805,3.194-3.194L6.687,7.494l.806-.806,3.194,3.194,3.194-3.194Z' transform='translate(-6.687 -6.688)'/%3E%3C/svg%3E");
                }
            }
        }
    }
}