@import '../../styles/shared';

// Colors
$switch--defaultColor: $lightGrey;
$switch--checkedColor: $color-primary;

// Size
$switch--width: 42px;
$switch--height: 24px;
$switch--inner-padding: 2px;
$switch--label-spacing: 12px;
$switch--font-size: 16px;
$switch--point-size: $switch--height - $switch--inner-padding*2;

$switch--transition-duration: 250ms;

.test-switch-component {
    padding: 20px;
    background-color: white;
    width: 100%;
    margin-top: 20px;
    box-shadow: 0 5px 14px rgba(35, 35, 35, 0.125);
}

.nw-switch {
    display: inline-block;
    @include user-select;
    cursor: pointer;

    &__switcher {
        display: inline-block;
        vertical-align: middle;
        width: $switch--width;
        height: $switch--height;
        border-radius: $switch--height;
        background-color: $switch--defaultColor;
        position: relative;
        transition: all $switch--transition-duration ease-out;

        &::before,
        &::after {
            content: "";
            display: block;
            width: $switch--point-size;
            height: $switch--point-size;
            border-radius: 50%;
            position: absolute;
            top: $switch--inner-padding;
            left: $switch--inner-padding;
            transition: all $switch--transition-duration ease-out;
        }
        
        &::before {
            background-color: white;
        }

        &::after {
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 63.63636364% auto;
        }
    }

    &__label {
        margin-left: $switch--label-spacing;
        vertical-align: middle;
        font-size: $switch--font-size;
    }

    &__input {
        display: none;
    
        &:checked {
            & + .nw-switch__switcher {
                background-color: $switch--checkedColor;
    
                &::before,
                &::after {
                    left: $switch--width - $switch--point-size - $switch--inner-padding ;
                }
    
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='10.36' viewBox='0 0 14 10.36'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%230098db;fill-rule:evenodd;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M39.422,32l-7.856,7.355-3.011-2.767L27,38.112l4.52,4.248h.019L41,33.492Z' transform='translate(-27 -32)'/%3E%3C/svg%3E");
                }
            }
        }
    }

    &--disabled {
        opacity: .5;
        cursor: no-drop;
    }

    &--has-label {
        padding-left: $switch--width + $switch--label-spacing;

        .nw-switch__switcher {
            margin-left: -($switch--width + $switch--label-spacing);
        }
    }
}

