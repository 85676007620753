@import '../../styles/shared';

$widget-spacing: 16px;
$widget-border-color: $lightGrey;
$widget-dark-border-color: rgba(255, 255, 255, .3);

.nw-widget {   
    &:not(:last-child) {
        border-bottom: 1px solid $widget-border-color;
        padding-bottom: $widget-spacing;
        margin-bottom: $widget-spacing;

        .db-hoc-sidebar & {
            border-bottom-color: $widget-dark-border-color;
        }
    }

    .nw-switch {
        &__label {
            font-size: $font-size--small;
        }
        + .nw-switch {
            margin-top: 14px;
        }
    }
}
