@import '../../styles/shared';

$nw-icon-size-default: 16px;

.nw-icon {
    display: inline-block;
	width: $nw-icon-size-default;
	height: $nw-icon-size-default;
	vertical-align: middle;
	fill: currentColor;
}

