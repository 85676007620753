@import '../../../styles/shared';

.db-forgot-page {
    @include displayFlex;
    align-items: center;
    min-height: 280px;
    &__content {
        width: 100%;

        .title {
            text-align: left;
            margin-left: 0;
        }

        .db-auth-page__content__action {
            &--single-button > div {
                justify-content: flex-end;
            }
        }
    }
}