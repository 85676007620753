@import '../../styles/shared';

$sector-border-color: $lightGrey100;
$sector-sidebar-width: 276px;
$sector-box-header-background-color: $lightGrey;
$sector-box-header-height: 46px;
$sector-list-item-height: 36px;
$sector-list-item-spacing: 14px;
$sector-filter-height: 30px;
$sector-filter-icon-size: 8px;
$sector-data-spacing: 22px;
$sector-data-column-spacing: 8px;
$sector-data-column-one-width: 22px;
$sector-data-column-three-width: 100px;
$sector-icon-arrow-width: 8px;
$sector-icon-arrow-height: 10px;

.nw-sector {
    border: 1px solid $sector-border-color;
    overflow-y: auto;

    @include desktop {
        max-height: 380px;
    }

    &__sidebar {
        position: relative;
        
        @include desktop {
            height: 100%;
            width: $sector-sidebar-width;
        }

        > .nw-sector-box {
            > .nw-sector-box__header {
                position: sticky;
                top: 0;
                z-index: 50;
            }
        }
    }

    &__content {
        flex: 1;
        display: none;
        overflow: hidden;

        @include desktop {
            display: block;
            height: 100%;
        }
    }

    @include desktop {
        @include displayFlex;
        height: 100%;
    }
}

.nw-sector-box {
    @include displayFlex;
    flex-direction: column;

    &__header {
        background-color: $sector-box-header-background-color;
        height: $sector-box-header-height;

        @include desktop {
            border-right: 1px solid $sector-border-color;
        }
    }

    &__body {
        font-size: $font-size--small;
        // flex: 1;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;

        @include desktop {
            max-height: 330px;
            &::before {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                position: absolute;
                right: 0;
                bottom: 0;
                background-color: $sector-border-color;
            }
        }
    }

    @include desktop {
        height: 100%;
    }
}

.nw-sector-filter {
    height: 100%;
    @include displayFlex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 6px;

    &__input {
        @include displayFlex;
        width: 100%;
        height: $sector-filter-height;
        @include border-radius($sector-filter-height);
        background-color: $white;
        position: relative;

        &-input {
            padding: 0 14px;
            height: 100%;
            flex: 1;
            overflow: hidden;

            input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                padding: 0;
                font-size: $font-size--small;
            }
        }

        &-close {
            @include displayFlex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0 $sector-filter-icon-size;

            button {
                color: $darkGrey;
            }
        }
    }

    
}

.nw-sector-data-title {
    height: 100%;
    @include displayFlex;
    align-items: center;
    padding: 0 $sector-data-spacing;
    
    .nw-checkmark {
        &__switcher {
            background-color: $white;
            border: 1px solid $sector-border-color;
        }
    }

    &__item {
        font-weight: $font-weight--bold;
        padding-right: $sector-data-column-spacing;
        box-sizing: content-box;

        &:nth-child(1) {
            width: $sector-data-column-one-width;
        }

        &:nth-child(2) {
            flex: 1;
        }

        &:nth-child(3) {
            width: $sector-data-column-three-width;
        }
    }

}

.nw-sector-list {
    &__item {
        @include desktop {
            border-right: 1px solid $sector-border-color;
        }
        
        &-header {
            display: block;
            width: 100%;
            border: none;
            height: $sector-list-item-height;
            line-height: $sector-list-item-height;
            align-items: center;
            padding: 0 $sector-list-item-spacing+$sector-icon-arrow-width 0 $sector-list-item-spacing;
            background-color: $lightGrey200;
            cursor: pointer;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            outline: none;
        }
        
        &:not(:last-child) {
            border-bottom: 1px solid $lightGrey400;
        }

        &-body {
            @include desktop {
                display: none;
            }
        }

        &.is-active {
            @include desktop {
                border-right-color: white;
                position: sticky;
                top: 0;
                z-index: 10;
            }
            

            .nw-sector-list__item-header {
                background-color: white;
                
                @include desktop {
                    &::after {
                        content: "";
                        width: 1px;
                        height: 100%;
                        background-color: white;
                        position: absolute;
                        right: -1px;
                        top: 0;
                    }
                }

                &::before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: $sector-icon-arrow-height/2 0 $sector-icon-arrow-height/2 $sector-icon-arrow-width;
                    border-color: transparent transparent transparent $darkBlue;
                    position: absolute;
                    top: 50%;
                    right: $sector-list-item-spacing/2;
                    transform: translateY(-50%) rotate(90deg);

                    @include desktop {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }
        }
    }
}

.nw-sector-data-list {
    padding: 0 $sector-data-spacing;

    &__item {
        @include displayFlex;
        align-items: center;
        height: $sector-list-item-height;

        &:not(:last-child) {
            border-bottom: 1px dashed $lightGrey400;
        }

        &--disabled {
            opacity: .5;
        }
    }

    &__col {
        padding-right: $sector-data-column-spacing;
        box-sizing: content-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;

        &:nth-child(1) {
            width: $sector-data-column-one-width;
        }

        &:nth-child(2) {
            flex: 1;
        }

        &:nth-child(3) {
            width: $sector-data-column-three-width;
        }

        &--related {
            position: absolute;
            right: 5%;
        }
    }
}