@import '../../styles/shared';

$search-input-height: 48px;
$search-input-background: $lightGrey300;
$search-input-placeholder-color: $darkGrey;
$search-input-font-size: 22px;
$search-input-spacing: 4px 24px;
$search-result-item-height: 44px;
$search-result-item-spacing: 2px 12px 2px 24px;
$search-result-max-height: $search-result-item-height * 6;

.nw-search-input {
    position: relative;
    font-family: $fontFamily--header;

    &__input {
        @include displayFlex;
        align-items: center;
        background-color: $search-input-background;
        border-radius: $search-input-height/2;

        .nw-search-input--show & {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        &-input {
            flex: 1;
            input {
                width: 100%;
                height: $search-input-height;
                font-family: $fontFamily--header;
                font-size: $search-input-font-size;
                background-color: transparent;
                border: none;
                outline: none;
                padding: $search-input-spacing;
                box-sizing: border-box;
    
                &::placeholder {
                    color: $search-input-placeholder-color;
                }
            }
        }

        &-close {
            @include displayFlex;
            align-items: center;
            justify-content: center;
            color: $darkGrey;
            height: $search-input-height;
            width: $search-input-height;

            .nw-button {
                padding: 8px;
            }
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1030;
    }
}

.nw-search-result {
    background-color: $search-input-background;
    border-bottom: 1px solid $lightGrey400;
    max-height: $search-result-max-height;
    overflow-y: auto;
    overflow-x: hidden;

    &__item {
        @include displayFlex;
        align-items: center;
        height: $search-result-item-height;
        border-top: 1px solid $lightGrey500;
        padding: $search-result-item-spacing;
        box-sizing: border-box;
    }

    &__content {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__name {
        > span {
            display: inline-block;
            vertical-align: middle;

            &::after {
                display: block;
                content: attr(title);
                font-weight: bold;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }

            .nw-search-result__item:hover & {
                font-weight: bold;
            }
        }
    }

    &__ticker {
        margin-left: 16px;
        vertical-align: middle;
    }

    &__actions {
        opacity: 0;
        visibility: hidden;
        transition: all 100ms ease-out;

        .nw-search-result__item:hover & {
            opacity: 1;
            visibility: visible;
        }

        .nw-button {
            font-family: $fontFamily--header;
            line-height: 24px;

            &--small {
                font-size: 13px;
                font-weight: bold;
                min-height: 24px;
                min-width: 0;
            }
        }
    }
}
