@import '../../styles/shared';

$stock-body-background: $lightGrey200;
$stock-border-color: $lightGrey;
$stock-containerMinHeight: 500px;

// Widget with Stock
.nw-widget-stock {
    &__header {
        @include displayFlex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .nw-button {
            margin-bottom: 16px;
            width: 100%;
            line-height: 36px;

            @include desktop {
                width: auto;
            }
        }

        .nw-sub-header {
            h2 { margin-bottom: $spacing--smallMargin/2;}
        }
    }

    &__body {
        &--loading {
            // flex-grow: 1;
            // @include displayFlex;
            // justify-content: center;
            // align-items: center;
            min-height: $stock-containerMinHeight;
            background: $stock-body-background;
            border: 1px solid $stock-border-color;

            .nw-spinner {
                &--spinning {
                    min-height: 500px;
                }
                &__loading {
                    position: none;
                    transform: none;
                }
            }
        }
    }
}

// Search Input

.nw-search-input-block {
    margin: 0 auto 28px;
    
    @include desktop {
        max-width: 800px;
        
    }
}