$text--h1Size: $font-size--header;
$text--h2Size: $font-size--big;
$text--h3Size: 20px;
$text--h4Size: $font-size--mid;
$text--h5Size: $font-size--base;
$text--h6Size: 15px;
$text--small: $font-size--small;

$fontFamily--regular: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', sans-serif;
$fontFamily--header: 'Univers Next For Morn',  -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;

// Title Heading
%title-heading {
  font-size: $font-size--header;
  font-weight: $font-weight--bold;
  line-height: $lineHeight--base;
  margin: 0;
}

%subtitle-heading {
  font-size: $font-size--base;
  font-weight: $font-weight--light;
  line-height: $lineHeight--base;
  color: $darkGrey;
  margin: 0;
}