@import '../../styles/shared/index';

$input--spacing: $spacing--baseMargin;
$input--height: $input--height;
$input--padding: 0 $spacing--basePadding;
$input--labelWidth: 120px;
$input--labelSpace: $spacing--baseMargin;
$input--labelFontWeight: $font-weight--medium;
$input--bgColor: $white;
$input--radius: $radius--smaller;
$input--borderColor: $color-base-border;
$input--errorColor: $color-error;

.input-group {
    background: $input--bgColor;
    border: 1px solid $input--borderColor;
    height: $input--height;
    padding: $input--padding;
    @include border-radius($input--radius);
    @include displayFlex;
    align-items: center;

    &--error {
        border-color: $input--errorColor;
    }

    &__label {
        margin-right: $input--labelSpace;
        text-transform: capitalize;
        font-weight: $input--labelFontWeight;
        width: $input--labelWidth;
        max-width: $input--labelWidth;
    }

    &__input {
        flex-grow: 1;
        @include displayFlex;

        input {
            width: 100%;
            flex-grow: 1;
            height: ($input--height)-2;
            border: none;
            &:focus {
                outline: none;
                background: unset;
            }
        }
    }

    + .input-group {
        margin-top: $input--spacing;
    }
}