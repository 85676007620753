// Alignment
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

// Text decoration
.text-line-through {
    text-decoration: line-through;
}

// Text color
$colors:(
	"lightestBlue": $lightestBlue,
	"blue": $blue,
    "skyBlue": $skyBlue,
    "darkBlue": $darkBlue,
    "darkGrey": $darkGrey,
    "cherryRed": $cherryRed,
    "green": $green,
);

@each $key,$val in $colors {
    .color-#{$key} {
      color: #{$val};
    }
}

// Font style
$fontWeight:(
	"light": $font-weight--light,
	"base": $font-weight--base,
    "medium": $font-weight--medium,
    "bold": $font-weight--bold,
);

@each $key,$val in $fontWeight {
    .font-weight-#{$key} {
      font-weight: #{$val};
    }
}

// Font size
$fontSize:(
	"smaller": $font-size--smaller,
	"small": $font-size--small,
    "base": $font-size--base,
    "mid": $font-size--mid,
    "big": $font-size--big,
    "header": $font-size--header,
);

@each $key,$val in $fontSize {
    .font-size-#{$key} {
      font-size: #{$val};
    }
}

// Spacing
$spacing-unit: 4px;

@for $i from 0 through 10 {
    .mt-#{$i} {
        margin-top: $i*$spacing-unit;
    }

    .mb-#{$i} {
        margin-bottom: $i*$spacing-unit;
    }

    .ml-#{$i} {
        margin-left: $i*$spacing-unit;
    }

    .mr-#{$i} {
        margin-right: $i*$spacing-unit;
    }

    .mx-#{$i} {
        margin-left: $i*$spacing-unit;
        margin-right: $i*$spacing-unit;
    }

    .my-#{$i} {
        margin-top: $i*$spacing-unit;
        margin-bottom: $i*$spacing-unit;
    }

    .pt-#{$i} {
        padding-top: $i*$spacing-unit;
    }

    .pb-#{$i} {
        padding-bottom: $i*$spacing-unit;
    }

    .pl-#{$i} {
        padding-left: $i*$spacing-unit;
    }

    .pr-#{$i} {
        padding-right: $i*$spacing-unit;
    }

    .px-#{$i} {
        padding-top: $i*$spacing-unit;
        padding-bottom: $i*$spacing-unit;
    }

    .py-#{$i} {
        padding-top: $i*$spacing-unit;
        padding-bottom: $i*$spacing-unit;
    }
}