@import '../../styles/shared';

// Default style
$stock-header-background: $lightGrey300;
$stock-body-background: $lightGrey200;
$stock-border-color: $lightGrey;
$stock-border-width: 1px;
$stock-head-font-size: $font-size--small;
$stock-body-font-size: $font-size--base;
$stock-head-color: $color-primary;
$stock-head-height: 36px;
$stock-cell-padding: 0 8px;
$stock-cell-height: 45px;
$stock-containerMinHeight: 500px;

$stock-name-minWidth: 220px;

// Disabled style
$stock-disabled-background: $white;
$stock-disabled-color: $darkGrey100;

// Button close
$stock-button-close-backround: $lightGrey100;
$stock-button-close-font-size: $font-size--small;
$stock-button-close-padding: 7px;
$stock-button-close-radius: 60px;
$stock-button-icon-size: 8px;

$empty--margin: $spacing--baseMargin + 10;
$empty-headingMargin: $spacing--smallMargin;
$button-margin: 20px;

.nw-stock-list {
    background-color: $stock-body-background;
    border: $stock-border-width solid $stock-border-color;
    @include displayFlex;
    flex-direction: column;
    
    @include desktop {
        min-height: $stock-containerMinHeight;
    }

    .page-loading {
        flex-grow: 1;
        @include displayFlex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 100;
    }

    .nw-checkmark {
        margin: 0;
    }

    &__btnRemoveStocks {
        margin: $button-margin;
        display: block;
        @include desktop {
            display: none;
        }
    }

    &__emptyContainer {
        flex-grow: 1;
        @include displayFlex;
        justify-content: center;
        align-items: center;

        &__empty-rows {
            text-align: center;
            margin: $empty--margin;
            
            h3 {
                margin-top: unset;
                margin-bottom: $empty-headingMargin;
            }
            p {
                margin-top: unset;
                color: $color-text;
                span, strong { display: block; }
                span {
                    font-weight: $font-weight--light;
                }
            }
    
            @include desktop {
                width: 60%;
            }
        }
    }
}

.nw-stock-header {
    @include displayFlex;
    background-color: $stock-header-background;

    &__col {
        padding: 0 8px;
        font-size: $stock-head-font-size;
        font-weight: bold;
        color: $stock-head-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: $stock-head-height;
        @include displayFlex;
        align-items: center;
        cursor: pointer;

        > span {
            width: 10px;
            margin-left: 5px;
            height: 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.2' height='5.201' viewBox='0 0 9.2 5.201'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23454545;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M6.582,12.141a.7.7,0,0,1-.978,0,.68.68,0,0,1,0-.969l3.908-3.83a.7.7,0,0,1,.979,0l3.908,3.83a.681.681,0,0,1,0,.969.7.7,0,0,1-.979,0L10,9,6.582,12.141Z' transform='translate(14.602 12.342) rotate(180)'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right center;
        }

        &.desc {
            > span {
                transform: rotate(180deg);
            }
        }

        &.asc {
            > span {
                transform: rotate(00deg);
            }
        }

        div {
            clear: both;
        }

        &:nth-child(2) {
            min-width: $stock-name-minWidth;
        }

        @include desktop {
            font-size: 100%;
            &:nth-child(2) {
                min-width: auto;
            }
        }

        &.nw-stock--action {
            @include displayFlex;
            justify-content: flex-end;
            button {
                display: none;
                @include desktop {
                    display: block;
                }
            }
        }
    }
}

.nw-stock-item {
    @include displayFlex;
    align-items: center;
    height: $stock-cell-height;
    border-top: $stock-border-width solid $stock-border-color;

    &:hover {
        background: white;
    }

    &__col {
        padding: $stock-cell-padding;
        font-size: $stock-body-font-size;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 80%;
        &:nth-child(2) {
            min-width: $stock-name-minWidth;
        }

        @include desktop {
            font-size: 100%;
            &:nth-child(2) {
                min-width: auto;
            }
        }
    }

    &--disabled {
        background-color: $stock-disabled-background;
        color: $stock-disabled-color;
    }
}

.nw-stock {
    &--action {
        text-align: right;
        flex: 1;
    }
}

.stock-button-close {
    display: none;
    vertical-align: middle;
    font-size: $stock-button-close-font-size;
    line-height: $lineHeight--small;
    padding: $stock-button-close-padding;
    border-radius: $stock-button-close-radius;
    max-width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    border: none;

    &--showAlways {
        display: inline-flex;
        align-items: center;
    }

    &::after {
        content: "";
        display: inline-block;
        width: $stock-button-icon-size;
        height: $stock-button-icon-size;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='7.999' viewBox='0 0 8 7.999'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23ccc;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M14.686,7.494l-3.193,3.194,3.194,3.194-.805.805-3.2-3.194L7.493,14.687l-.806-.805,3.194-3.194L6.687,7.494l.806-.806,3.194,3.194,3.194-3.194Z' transform='translate(-6.687 -6.688)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    span {
        padding: 0 $stock-button-icon-size;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .nw-stock-item:hover & {
        display: inline-flex;
        align-items: center;
    }
    

    .nw-stock-item--disabled & {
        display: inline-flex;
        align-items: center;
        background-color: $stock-button-close-backround;
    }
}