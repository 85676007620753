$lightestBlue: #EAF1F6;
$blue: #0025A8;
$skyBlue: #0098DB;
$darkBlue: #062044;
$darkGrey: #9A9A9A;
$darkGrey100: #999999;
$black: #000000;
$white: #ffffff;
$black-panda:#454545;
$cherryRed:    #EE4054;
$green:        #42B983;
$greenDark: #30a372;
$greenLight: #e3fcef;
$lightGrey: #eaeaea;
$lightGrey100: #e0e0e0;
$lightGrey200: #f6f6f6;
$lightGrey300: #f0f0f0;
$lightGrey400: #eaeaea;
$lightGrey500: #fafafa;

// App's colors
$color-primary: $skyBlue !default;
$color-secondary: $darkBlue !default;
$color-tertiary: $blue !default;

// Text colors
$color-heading: $black !default;
$color-heading-inverted: $white !default;
$color-text: $black-panda !default;
$color-text-inverted: $white !default;
$color-text-outline: $darkGrey !default;
$color-subText: $darkGrey !default;

// Borders
$color-base-border: $lightestBlue !default;
// $color-light-border: $light-grey !default;
// $color-lightest-border: $lightest-grey !default;

// Notification
$color-error: $cherryRed !default;
$color-success: $green !default;
