body {
    font-family: $fontFamily--regular;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4 {
  font-family: $fontFamily--header;
}

h1 { font-size: $text--h1Size; }
h2 { font-size: $text--h2Size; }
h3 { font-size: $text--h3Size; }
h4 { font-size: $text--h4Size; }
h5 { font-size: $text--h6Size; }
h6 { font-size: $text--h6Size; }

.nw-widget {
  &:not(h1, h2, h3, h4) {
    font-size: $text--small;
  }
}