@import '../../../styles/shared';

$container--padding: $spacing--basePadding;
$container--remembermeSpace: $spacing--baseMargin;
$container--paddingMobile: $container--padding/2;
$container--buttonSpaceTop: $spacing--baseMargin;
$container--titleWeight: $font-weight--light;
$container--titleColor: $white;
$container--linkcolor: $color-primary;
$container--titleMargin: 0 0 $spacing--baseMargin+10 0;

.db-login-page {

    .title {
        text-align: center;
        margin: 20px 0px;
    }

    .db-auth-page__content__action {
        & > div {
            @include displayFlex;
            justify-content: space-between;
            margin-top: $container--buttonSpaceTop;
        }

        & p {
            color: $container--titleColor;
            font-size: 11px;
            line-height: 14px;
            margin: 0;

            & a {
                color: $container--linkcolor;
            }
        }

        label {
            @include displayFlex;
            align-items: center;
            margin: $container--remembermeSpace 0;

            span {
                color: $white;
                margin-left: 10px;
                margin-top: 2px;
            }
        }

        @include iPhone {
            label {
                margin: 5px 0 10px;
            }
            & p {
                font-size: 10px;
                line-height: 11px;
            }
        }
    }

    @include desktop {
        .title {
            font-size: $font-size--header;
            margin: $container--titleMargin;
        }
    }
}