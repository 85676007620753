// Icons
$icon--smallSize: 16px !default;
$icon--baseSize: 24px !default;

// Containers
$app--height: 100vh !default;
$app--width: 100vw !default;
$footer--barSize: 52px !default;

// Navbar
$navBar--height:   54px !default;
$navBar--dashboardHeight: 64px !default;

// Buttons
$button--smallSize: 28px !default;
$button--baseSize: 44px !default;
$button--largeSize: 62px !default;
$button--midSize: 36px !default;
$button--submitSize: 52px !default;

// Avatar
$avatar--smallSize: 32px !default;
$avatar--baseSize: 48px !default;
$avatar--largeSize: 64px !default;

// Radius
$radius--smaller: 3px !default;
$radius--small: 12px !default;
$radius--base: 26px !default;
$radius--arrow: 8px !default;
$radius--full: 100% !default;

// Device Sizes
$desktop--minWidth: 900px !default; 
$minWidthIphone: 375px !default;
$maxHeightIphone: 667px !default;

// Font size
$font-size--smaller: 12px !default;
$font-size--small: 14px !default;
$font-size--base: 16px !default;
$font-size--mid: 18px !default;
$font-size--big: 24px !default;
$font-size--header: 28px !default;

//Font weight
$font-weight--light: 300;
$font-weight--base: 400;
$font-weight--medium: 500;
$font-weight--bold: 600;

// Line Height
$lineHeight--small: 100% !default;
$lineHeight--base: 135% !default;
$lineHeight--header: 160% !default;

// Text Shadows
$textShadow: 0px 0px 8px rgba(35,35,35,0.325);

// Input
$input--height: 54px !default;
$input--minHeight: 48px !default;
$input--label: 14px;

//General Spacing
$spacing--smallMargin: 10px !default;
$spacing--baseMargin: 20px !default;
$spacing--bigMargin: 30px !default; //$common-margin
$spacing--smallPadding: 10px !default;
$spacing--basePadding: 20px !default;
$spacing--bigPadding: 50px !default;

//transition animation
$transition-animate: 500ms cubic-bezier(0.77, 0, 0.175, 1);

//Line height
// $commonLineHeight: 24px;

// List
$item--minHeight: 48px !default;
