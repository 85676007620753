@import '../../styles/shared';

// Header
$hoc-header-height: 80px;
$hoc-header-padding: 6px 8px;
$hoc-header-padding-desktop: 3px 26px;
$hoc-header-background: white;
$hoc-header-shadow: 0px 3px 6px #00000029;
$hoc-header-title-size: 20px;
$hoc-header-brand-spacing: 64px;
$hoc-header-brand-width: 250px;

// Navbar
$hoc-navbar-item-spacing: 24px;
$hoc-navbar-item-size: $font-size--base;
$hoc-navbar-item-active-color: $color-primary;
$hoc-navbar-item-active-border-weight: 3px;

// Content
$hoc-content-padding: 16px 50px;

// Sidebar
$hoc-sidebar-padding: 16px;
$hoc-sidebar-padding-desktop: 16px 24px;
$hoc-sidebar-width: 300px;

.db-hoc-navbar {
    background-color: $hoc-header-background;
    padding: $hoc-header-padding;
    box-shadow: $hoc-header-shadow;
    @include displayFlex;
    align-items: center;
    // flex-direction: row-reverse;
    justify-content: space-between;
    
    @include desktop {
        // flex-direction: row;
        height: $hoc-header-height;
        padding: $hoc-header-padding-desktop;
    }

    &__title {
        flex: 1;
        // display: none;

        // @include desktop {
        //     display: block;
        // }

        button {
            text-decoration: none;
            color: $color-heading;
            border: unset;
            background: unset;
            &:hover {
                cursor: pointer;
            }

            h2 {
                margin: unset;
                font-size: 100%;
                text-align: left;

                @include desktop {
                    font-size: $text--h2Size;
                }
            }
        }

        h3 {
            margin: 0;
            font-size: $font-size--base;
            font-weight: $font-weight--medium;

            @include desktop {
                font-size: $hoc-header-title-size;
            }
        }
    }

    &__nav {
        margin: 0;
        padding: 0;

        li {
            list-style: none;

            &:not(:last-child) {
                margin-right: $hoc-navbar-item-spacing
            }

            a,
            span {
                font-size: $hoc-navbar-item-size;
                font-weight: $font-weight--bold;
                text-decoration: none;
                padding: 4px 0;
                color: $black;
                cursor: pointer;

                &:hover,
                &:focus,
                &:active {
                    color: $black;
                }
            }

            &.active {
                a,
                span {
                    border-bottom: $hoc-navbar-item-active-border-weight solid $hoc-navbar-item-active-color;
                }
            }
        }
    }

    &__brand {
        &__desktop {
            display: none;
        }
        &__mobile {
            width: 40px;
            margin-left: 20px;
        }


        @include desktop {
            margin-left: $hoc-header-brand-spacing/2;

            &__desktop {
                width: $hoc-header-brand-width;
                display: block;
            }

            &__mobile {
                display: none;
            }
        }
    }
}

.db-hoc-main {
    @include displayFlex;
    flex-wrap: wrap;
}

.db-hoc-content {
    flex: 1;
    padding: $hoc-sidebar-padding;
    width: 100%;

    @include desktop {
        padding: $hoc-content-padding;
    }
}

.db-hoc-sidebar {
    width: 100%;
    background-color: $darkBlue;
    color: white;
    padding: $hoc-sidebar-padding;

    @include desktop {
        width: $hoc-sidebar-width;
        padding: $hoc-sidebar-padding-desktop;
        min-height: calc(100vh - #{$hoc-header-height});
    }
}