@import '../../styles/shared';

$spinner--color: $color-primary;
$spinner--size: 24px;

.nw-spinner {
  position: relative;
  width: 100%;

  &__loading {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--spinning {
    min-height: 100%;
    .nw-spinner__loading {
      display: block;
    }
  }

  svg {
      max-width: $spinner--size;
      max-height: $spinner--size;
    circle {
      stroke: $spinner--color;
    }
  }
}