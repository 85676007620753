@import '../../styles/shared';

// Modal color
$modal-shadow-color: #00000029;
$modal-overlay-color: rgba($color: #000000, $alpha: .5);
$modal-content-color: white;

// Modal spacing
$modal-inner-padding-x: 40px;
$modal-inner-padding-y: 30px;
$modal-button-spacing: 20px;
$modal-tickercode-spacing: 22px;

// Modal size
$modal-width-default: 800px;
$modal-width-small: 500px;
$modal-margin: 84px;
$modal-shadow: 0px 5px 10px $modal-shadow-color;

$modal-widgetHeader-spacing: $spacing--baseMargin;

.nw-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    overflow: hidden;
    z-index: 1030;

    &__dialog {
        position: relative;
        height: 100%;
        @include displayFlex;
        flex-direction: column;
        max-width: auto;

        @include desktop {
            min-width: $modal-width-small;
            max-width: $modal-width-default;
            margin: auto;
            height: calc(100% - #{$modal-margin*2});
            align-items: center;
            justify-content: center;

            .nw-modal--small & {
                max-width: $modal-width-small;
            }

            .nw-modal--center & {
                @include displayFlex;
                align-items: center;
            }
        }
    }

    &__content {
        @include displayFlex;
        flex-direction: column;
        background-color: $modal-content-color;
        box-shadow: $modal-shadow;
        width: 100%;
        height: 100%;
        max-height: 100%;
        position: relative;
        z-index: 1;

        @include desktop {
            height: auto;
        }
    }

    &__header {
        padding: $modal-inner-padding-y/2 $modal-inner-padding-x/2;

        @include desktop {
            padding: $modal-inner-padding-y $modal-inner-padding-x;
        }
    }

    &__subtitle {
        @extend %subtitle-heading;
    }

    &__title {
        @extend %title-heading;

        small {
            font-size: $font-size--mid;
            font-weight: $font-weight--light;
            margin-left: $modal-tickercode-spacing;
            color: $darkGrey;
        }
    }

    &__body {
        padding: 0 $modal-inner-padding-x/2;
        // flex: 1;
        overflow-y: auto;

        > p {
            margin-top: unset;
        }

        @include desktop {
            padding: 0 $modal-inner-padding-x;
        }
        
        .nw-widget {
            .nw-sub-header {
                margin-bottom: $modal-widgetHeader-spacing;
            }
        }
    }

    &__footer {
        padding: $modal-inner-padding-y/2 $modal-inner-padding-x/2;
        @include displayFlex;
        justify-content: flex-end;

        >:not(:last-child) {
            margin-right: $modal-button-spacing;
        }
        
        @include desktop {
            padding: $modal-inner-padding-y $modal-inner-padding-x;
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $modal-overlay-color;
    }

    &--open {
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }

    &--small-spacing {
        .nw-modal__header {
            padding-bottom: $modal-inner-padding-y/4;
        }
    }
}