@import '../../styles/shared';

.nw-sub-header {
    h1,
    h2,
    h3,
    h4 {
        margin-top: 0;
        margin-bottom: .5em;
    }

    p {
        margin-top: 0;
        font-size: $font-size--small;
    }
}