@import '../../styles/shared';

$page--height: 100vh;
$page--width: 100vw;
$page--bg: rgba(35,35,35,0.525);

$spinner--size: 44px;
$spinner--color: $color-secondary;

.page-loader {
    .nw-spinner {
        visibility: hidden;
    }
    &--loading {
        width: $page--width;
        height: $page--height;
        background: $page--bg;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        @include displayFlex;
        align-items: center;
        justify-content: center;
        .nw-spinner {
            visibility: visible;

            svg {
                max-width: $spinner--size;
                max-height: $spinner--size;
                circle {
                    stroke: $spinner--color;
                }
            }
        }
    }
}