
// for RTL
%transform-rtl {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

// Use for all notification colors
.notifications {
    &--error { color: $color-error; }
    &--success { color: $color-success; }
}